(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            QUOTE_REQUEST_ADD_SITE: 'Site',
            QUOTE_REQUEST_ADD_CONTACT_DETAILS: 'Site Contact Details',
            QUOTE_REQUEST_ADD_DETAILS: 'Quote Details',

            INCLUDE_NEVER_SUBMITTED: 'Include Never Submitted'
        });
    }
})();


app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        QUOTE_REQUEST: 'Quote Request',
        QUOTE_REQUESTS: 'Quote Requests',

        QUOTE_REQUEST_LIST: 'Quote Requests',

        QUOTE_REQUEST_ADD: 'Add a new Quote Request',

        QUOTE_REQUEST_CREATED: 'Quote Request Created',
        QUOTE_REQUEST_UPDATED: 'Quote Request Updated',
        QUOTE_REQUEST_DELETED: 'Quote Request Deleted',

        QUOTE_REQUEST_DETAILS: 'Quote Request Details',
        QUOTE_REQUEST_CONTACTS: 'Quote Request Contacts',

        QUOTE_REQUEST_STATUS_CREATED: 'Created',
        QUOTE_REQUEST_STATUS_REQUESTED: 'Requested',
        QUOTE_REQUEST_STATUS_PART_QUOTED: 'Part Quoted',
        QUOTE_REQUEST_STATUS_QUOTED: 'Quoted',
        QUOTE_REQUEST_STATUS_REJECTED: 'Rejected',
        QUOTE_REQUEST_STATUS_ACCEPTED: 'Accepted',
        QUOTE_REQUEST_STATUS_CANCELLED: 'Cancelled',
        QUOTE_REQUEST_STATUS_EXPIRED: 'Expired',
        QUOTE_REQUEST_STATUS_DECLINED: 'Declined',
        QUOTE_REQUEST_STATUS_EXTENSION_PENDING: 'Extension Requested',
        CREATED_FROM_JOB: 'Created From Job',
        QUOTE_WORKFLOW_REOPEN_SUCCESS: 'Quote Request Reopen Success',
        QUOTE_WORKFLOW_CANCEL_SUCCESS: 'Quote Request Cancel Success',
        NEW_COMPLETION_DATE_MUST_BE_AFTER: 'The new completion date must be after the current completion date',
        SUPPORTING_DOCUMENT: 'Supporting Files',

        AT_LEAST_ONE_SERVICE_PROVIDER_DOES_NOT_HAVE_ACTIVE_PARTNERSHIP_WITH_SITE: 'Service Provider does not have an active partnership with the site you are raising the quote request for',
        ADD_SUB_QUOTES: 'Add Sub Quotes',
        SUB_QUOTE: 'Sub Quote',
        CANNOT_CREATE_DUPLICATE_QUOTES: 'Cannot create duplicate quotes',
        QUOTES_SELECTED: 'Quotes selected',
        QUOTE_FOR_BUDGET_PURPOSE_ONLY: 'Quote for budget purposes only?',
        BUDGET_QUOTE_NOT_ALLOWED: 'Budget Quote Setting is disabled',
        BUDGET_QUOTE_ALREADY_CONVERTED: 'Budget Quote already converted to Full Quote.',
        BUDGET_QUOTE: 'Budget Quote',
    });
});
