(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsOptionsController', CommonQuoteRequestDetailsQuoteDetailsOptionsController);

    CommonQuoteRequestDetailsQuoteDetailsOptionsController.$inject = ['quoteOptionCollectionResponse', 'quoteResponse'];

    function CommonQuoteRequestDetailsQuoteDetailsOptionsController(quoteOptionCollectionResponse, quoteResponse) {
        var vm = this;
        vm.quoteOptionCollectionResponse = quoteOptionCollectionResponse;
        vm.quoteResponse = quoteResponse;
    }
})();
