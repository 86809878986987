app.factory('AjaxRequestInterceptor', function ($rootScope, $q, $window, $injector, $translate, messenger, lodash, $location) {
    return {
        request: function (config) {
            config.headers = config.headers || {};
            if (config.token) {
                config.headers.Authorization = 'Bearer ' + config.token;
            } else if ($window.localStorage.getItem('token')) {
                config.headers.Authorization = 'Bearer ' + $window.localStorage.getItem('token');
            }
            return config;
        },
        response: function (response) {
            return response || $q.when(response);
        },
        responseError: function (response) {
            if (response.status === 401) {
                if ($window.location.href.indexOf('login') > -1) {
                    $rootScope.$broadcast('loginLoading');
                } else {
                    $injector.get('$uibModalStack').dismissAll();
                    $injector.get('userManager').logout();
    
                    /**
                     * $location.search() doesn't work properly without html5Mode
                     * Don't want to play with html5Mode right now, since it means updating nginx FE server block, etc
                     * If destination is already set (bad credentials on login), leave alone.
                     * If not, set to the current/previous route
                     */
                    var existingDestination = /destination=([^&]*)/g.exec($window.location.hash);
                    var dest = existingDestination ? existingDestination[1] : $location.url().encode();
    
                    $injector.get('$state').transitionTo('login', { destination: dest });
                }
            } else if (response.status === 409 || response.status === 400 || response.status === 403) {
                angular.element('i.loading-spinner').removeClass('fa-spinner fa-pulse');

                if (response.data) {
                    lodash.each(response.data, function(error) {
                        if (error.exception && error.exception.length) {
                            lodash.each(error.exception, function(exception) {
                                if (exception.message) {
                                    messenger.error($translate.instant(exception.message));
                                }
                            });
                        } else {
                            if (error.property_path && typeof error.message === 'string') {
                                messenger.error($translate.instant(error.message, {path: error.property_path}));
                            } else if (typeof error.message === 'string') {
                                messenger.error(error.message);
                            } else {
                                for(var key in error.message) {
                                    messenger.error(error.message[key]);
                                }
                            }
                        }
                    });

                    return;
                } else {
                    messenger.error('THIS_ENTITY_HAS_DEPENDENCIES');
                }
            } else if (response.status === 500){
                messenger.error('REQUEST_ERROR');
            }
            return $q.reject(response);
        }
    };
});

app.config(function ($httpProvider) {
    $httpProvider.interceptors.push('AjaxRequestInterceptor');
});
