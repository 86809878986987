app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        MONTH: 'Month',
        YEAR: 'Year',
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
        YEARLY: 'Yearly',
        FIRST: 'First',
        SECOND: 'Second',
        THIRD: 'Third',
        FOURTH: 'Fourth',
        LAST: 'Last',
        DAY: 'Day',
        DATE_STARTEDAT: 'Starts',
        DATE_EXPIREDAT: 'Expires',
        DATE_DOCUMENT_DATE: 'Document Date',
        DATE_EXPIRESAT: 'Expires',
        DATE_REVIEWEDAT: 'Review At',
        DATE_TENDEREDAT: 'Tendered At',
        DATE_CREATEDAT: 'Created At',
        DATE_UPLOADDATE: 'Uploaded Date',
        DATE_COMPLETIONAT: 'Completion Due At',
        DATE_CREATED: 'Created Date',

        DATE_LAST_READ: 'Read At',
        DATE_ACTIVEAT: 'Active At',
        DATE_INACTIVEAT: 'Inactive At',

        TASK_PLANNER_DAILY: '{{interval}}D',
        TASK_PLANNER_WEEKLY: '{{interval}}W',
        TASK_PLANNER_MONTHLY: '{{interval}}M',
        TASK_PLANNER_ANNUALLY: '{{interval}}Y',

        /* Generation */
        RELATIVE_STARTDATE: 'Relative start date',
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
        LAST_WEEKDAY: 'Last Weekday',

        BYDAY_MONDAY: 'Monday',
        BYDAY_TUESDAY: 'Tuesday',
        BYDAY_WEDNESDAY: 'Wednesday',
        BYDAY_THURSDAY: 'Thursday',
        BYDAY_FRIDAY: 'Friday',
        BYDAY_SATURDAY: 'Saturday',
        BYDAY_SUNDAY: 'Sunday',

        BYDAY_MONDAY_FIRST: 'First Monday of the month',
        BYDAY_TUESDAY_FIRST: 'First Tuesday of the month',
        BYDAY_WEDNESDAY_FIRST: 'First Wednesday of the month',
        BYDAY_THURSDAY_FIRST: 'First Thursday of the month',
        BYDAY_FRIDAY_FIRST: 'First Friday of the month',
        BYDAY_SATURDAY_FIRST: 'First Saturday of the month',
        BYDAY_SUNDAY_FIRST: 'First Sunday of the month',

        BYDAY_MONDAY_LAST: 'Last Monday of the month',
        BYDAY_TUESDAY_LAST: 'Last Tuesday of the month',
        BYDAY_WEDNESDAY_LAST: 'Last Wednesday of the month',
        BYDAY_THURSDAY_LAST: 'Last Thursday of the month',
        BYDAY_FRIDAY_LAST: 'Last Friday of the month',
        BYDAY_SATURDAY_LAST: 'Last Saturday of the month',
        BYDAY_SUNDAY_LAST: 'Last Sunday of the month',

        SECONDS: 'Seconds',
        MINUTES: 'Minutes',
        HOURS: 'Hours',
        DAYS: 'Days',
        WEEKS: 'Weeks',
        YEARS: 'Years',
        NUMBER_MINUTES: '{{ minutes }} Minute(s)',

        JANUARY: 'January',
        FEBRUARY: 'February',
        MARCH: 'March',
        APRIL: 'April',
        MAY: 'May',
        JUNE: 'June',
        JULY: 'July',
        AUGUST: 'August',
        SEPTEMBER: 'September',
        OCTOBER: 'October',
        NOVEMBER: 'November',
        DECEMBER: 'December',

        JAN: 'Jan',
        FEB: 'Feb',
        MAR: 'Mar',
        APR: 'Apr',
        JUN: 'Jun',
        JUL: 'Jul',
        AUG: 'Aug',
        SEP: 'Sep',
        OCT: 'Oct',
        NOV: 'Nov',
        DEC: 'Dec',

        CAL_JANUARY: 'JANUARY',
        CAL_FEBRUARY: 'FEBRUARY',
        CAL_MARCH: 'MARCH',
        CAL_APRIL: 'APRIL',
        CAL_MAY: 'MAY',
        CAL_JUNE: 'JUNE',
        CAL_JULY: 'JULY',
        CAL_AUGUST: 'AUGUST',
        CAL_SEPTEMBER: 'SEPTEMBER',
        CAL_OCTOBER: 'OCTOBER',
        CAL_NOVEMBER: 'NOVEMBER',
        CAL_DECEMBER: 'DECEMBER',
        CAL_SUNDAY: 'Sunday',
        CAL_MONDAY: 'Monday',
        CAL_TUESDAY: 'Tuesday',
        CAL_WEDNESDAY: 'Wednesday',
        CAL_THURSDAY: 'Thursday',
        CAL_FRIDAY: 'Friday',
        CAL_SATURDAY: 'Saturday',
        CAL_SUNDAY_SHORT: 'Sun',
        CAL_MONDAY_SHORT: 'Mon',
        CAL_TUESDAY_SHORT: 'Tue',
        CAL_WEDNESDAY_SHORT: 'Wed',
        CAL_THURSDAY_SHORT: 'Thu',
        CAL_FRIDAY_SHORT: 'Fri',
        CAL_SATURDAY_SHORT: 'Sat',

        EXPIRESAT: 'Expires At',

        DATE_SHORT: 'dd/mm/yyyy',
        MONTH_YEAR: 'mm/yyyy',

        DATE_FROM_LABEL: 'From date:',
        DATE_TO_LABEL: 'To date:',

        START_MONTH: 'Start Month',
        END_MONTH: 'End Month'
    });
});
