(function() {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('quoteRequestService', QuoteRequestService);

    QuoteRequestService.$inject = ['$translate'];

    function QuoteRequestService($translate) {
        var self = this;

        this.getStatusesAsObject = getStatusesAsObject;
        this.getQuoteStatusesAsObject = getQuoteStatusesAsObject;
        this.getQuoteStatusesAsArray = getQuoteStatusesAsArray;

        function getStatusesAsObject() {
            var statuses = [
                {
                    value: null,
                    title: $translate.instant('NONE_SELECTED')
                },
                {
                    value: 'created',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_CREATED')
                },
                {
                    value: 'requested',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_REQUESTED')
                },
                {
                    value: 'part_quoted',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_PART_QUOTED')
                },
                {
                    value: 'quoted',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_QUOTED')
                },
                {
                    value: 'rejected',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_REJECTED')
                },
                {
                    value: 'accepted',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_ACCEPTED')
                },
                {
                    value: 'cancelled',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_CANCELLED')
                },
                {
                    value: 'expired',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_EXPIRED')
                },
                {
                    value: 'declined',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_DECLINED')
                },
                {
                    value: 'extension_requested',
                    title: $translate.instant('QUOTE_REQUEST_STATUS_EXTENSION_PENDING')
                },
            ];

            statuses.sort(function(a, b) {
                if (a.value === null) {
                    return -1;
                }

                return a.title > b.title ? 1 : (a.title < b.title ? -1 : 0);
            });

            return statuses;
        }

        var quoteStatuses = [
            'requested',
            'submitted',
            'accepted',
            'rejected',
            'cancelled',
            'expired',
            'abstained',
            'pending',
            'information_requested',
            'extension_requested',
            'value_pending_approval'
        ];

        var performanceStatisticQuoteStatuses = [
            'requested',
            'submitted',
            'accepted',
            'rejected',
            'expired',
            'pending',
            'information_requested'
        ];

        function getQuoteStatusesAsObject(includeBudgetQuoteStatus) {
            var statuses = [];

            if (includeBudgetQuoteStatus && quoteStatuses.indexOf('budget_quote_submitted') === -1) {
                quoteStatuses.push('budget_quote_submitted');
            } else if (!includeBudgetQuoteStatus && quoteStatuses.indexOf('budget_quote_submitted') !== -1) {
                const index = quoteStatuses.indexOf('budget_quote_submitted');
                quoteStatuses.splice(index, 1);
            }

            angular.forEach(quoteStatuses, function(quoteStatus) {
                statuses.push({
                    value: quoteStatus,
                    title: $translate.instant('QUOTE_STATUS_' + quoteStatus.toUpperCase())
                });
            });

            statuses.sort(function(a, b) {
                if (a.value === null) {
                    return -1;
                }

                return a.title > b.title ? 1 : (a.title < b.title ? -1 : 0);
            });

            return statuses;
        }

        function getQuoteStatusesAsArray(statuses) {
            switch (statuses) {
                case 'all':
                    return quoteStatuses;
                case 'performancestatistic':
                    return performanceStatisticQuoteStatuses;
                default:
                    return quoteStatuses;
            }
        }

        return this;
    }
})();
