(function () {
    'use strict';

    angular.module('elogbooks.admin.client-billing').controller('ClientBillingTrendGraphController', ClientBillingTrendGraphController);

    function ClientBillingTrendGraphController(reportsCollection, lodash, moment, $translate) {
        var vm = this;
        vm.highestValue = 0;
        vm.chartMonths = [];
        vm.chartData = [];

        if (reportsCollection && reportsCollection.count) {
            var values = [];
            vm.reports = reportsCollection.clientbillingreports
                .sort((a, b) => new Date(a.file.createdAt) - new Date(b.file.createdAt))
                .filter(obj => obj['valuePerInvoiceFrequency'] >= 0);

            lodash.each(vm.reports, function (value, key) {
                if (parseInt(value.valuePerInvoiceFrequency) > vm.highestValue) {
                    vm.highestValue = parseInt(value.valuePerInvoiceFrequency);
                }

                values.push({
                    x: key , y: value.valuePerInvoiceFrequency
                });

                vm.chartMonths.push(moment(value.file.createdAt, 'YYYYMM').format('MMM-YY'));
            });

            vm.highestValue = Math.ceil(vm.highestValue * 1.2 / 1000) * 1000;
            vm.chartData.push({
                key: $translate.instant('VALUE_PER_INVOICING_FREQUENCY'), color: '#19aa8d', values: values
            });
        }

        vm.chartOptions = {
            chart: {
                type: 'lineChart',
                height: 450,
                margin: {
                    top: 0, right: 20, bottom: 40, left: 70
                },
                stacked: true,
                reduceXTicks: true,
                showMaxMin: true,
                showControls: true,
                showLegend: true,
                yDomain: [0, vm.highestValue],
                x: function (d) {
                    return d.x;
                },
                y: function (d) {
                    return d.y;
                },
                useInteractiveGuideline: false,
                xAxis: {
                    tickFormat: function (d) {
                        return vm.chartMonths[d];
                    },
                    showMaxMin: false,
                },
                yAxis: {
                    tickFormat: function (d) {
                        if (d > 0) {
                            return '£' + d3.format(',.f')(d);
                        }
                    },
                    axisLabelDistance: -5,
                    showMaxMin: false
                },
                legend: {
                    align: false,
                    padding: 0,
                    width: 100,
                    margin: {
                        top: 2, right: 5, bottom: 20, left: 0
                    }
                }
            }
        };
    }
})();
