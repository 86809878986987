(function() {
    'use strict';

    angular
        .module('elogbooks.common.dashboard-layout')
        .controller('DashboardLayoutController', DashboardLayoutController);

    function DashboardLayoutController(
        rootResourceResponse,
        $rootScope,
        $scope,
        userManager,
        elbSettings,
        modules,
        apiClient,
        user,
        $interval,
        $timeout,
        $state,
        $translate,
        siteCollectionResponse,
        base64,
        messenger,
        $uibModal,
        $http,
        $window,
        API_URL,
        modulesService,
        lodash,
        moment,
        systemNotifications,
        ssoService,
        ServiceProviderFeedbackService,
        notificationsService
    ) {
        var vm = this;
        $rootScope.refreshToken = null;
        vm.user = user;
        vm.refresh = refresh;
        vm.logout = logout;
        vm.currentYear = new Date().getFullYear();
        vm.markAsRead = markAsRead;
        vm.markAllAsRead = markAllAsRead;
        vm.brandLogo = elbSettings.getSetting('general_logo');
        vm.helpdeskNumber = elbSettings.getSetting('general_helpdesk_number') ? elbSettings.getSetting('general_helpdesk_number').value : null;
        vm.trainingLink = elbSettings.getSetting('general_training_link');
        vm.originalUser = userManager.originalUser;
        vm.revertToOriginalUser = revertToOriginalUser;
        vm.checkWidgetRelatedRoutes = checkWidgetRelatedRoutes;
        vm.checkActiveState = checkActiveState;
        vm.isCollapsed = true;
        vm.themePrefix = rootResourceResponse ? rootResourceResponse.theme : 'elb';
        vm.loading = false;

        vm.userInitials = userManager.getUserInitials(user);
        vm.goToJob = goToJob;
        vm.goToQuote = goToQuote;
        vm.goToNotificationLink = goToNotificationLink;
        var sessionExpirationModal = null;
        var interval = null;
        var updatesTimeout = null;

        if (rootResourceResponse.getLink('get-sp-for-feedback') &&
            (
                !$window.localStorage.getItem('originalUser') ||
                elbSettings.getSetting('sp_feedback_allow_on_login_as').value
            )
        ) {
            $rootScope.feedbackRequired = true;
            ServiceProviderFeedbackService.openSpFeedbackModal(
                rootResourceResponse.getLink('get-sp-for-feedback'),
                $state.current.name,
                rootResourceResponse
            );
        }

        if (userManager.getPreferenceValue('notificationMethod') !== 'email') {
            vm.notifications = {list: null, unread: 0, listTotal: 0, page: 1};
            vm.getNotifications = getNotifications;
        } else {
            vm.hideNotifications = true;
        }

        userManager.setUser(user);

        vm.modules = sortModules(filterModules(modules.data));

        var triggerSiteModal = function() {
            $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: '/modules/common/dashboard-layout/quick-site-action-modal.html',
                controller: 'QuickSiteActionModalController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    site: function(apiClient) {
                        var siteUrl = vm.selectedSiteModel.selected.object.getLink('self');
                        vm.selectedSiteModel.clear();

                        return apiClient.get(siteUrl).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                }
            });
        };

        vm.selectedSiteModel = {
            response: siteCollectionResponse,
            link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            searchKey: 'residenceFilter',
            onSelect: triggerSiteModal,
            populateDisabled: true
        };

        refresh();

        function refresh() {
            $interval.cancel($rootScope.refreshToken);
            $rootScope.refreshToken = null;

            if (!document.hidden) {
                apiClient.get(user._links.alerts.href).then(function (response) {
                    if (response) {
                        if ('undefined' !== typeof vm.user._links) {
                            vm.alerts = response.alerts;
                            vm.buddies = response.alerts.buddies;
                        }

                        if (lodash.has(response, 'alerts.system_notifications_count')) {
                            if (response.alerts.system_notifications_count > 0
                                && user.getLink('system-notifications')
                                && response.alerts.system_notifications_count !== systemNotifications.count()
                            ) {
                                apiClient.get(user.getLink('system-notifications')).then(function (response) {
                                    if (response.systemNotifications) {
                                        systemNotifications.setNotifications(response.systemNotifications);
                                    }
                                });
                            } else if (response.alerts.system_notifications_count === 0) {
                                systemNotifications.clean();
                            }
                        }
                    }
                });

                if (userManager.getPreferenceValue('notificationMethod') !== 'email') {
                    apiClient.get(user._links.notificationCount.href).then(function (response) {
                        if (response) {
                            if ('undefined' !== typeof vm.user._links) {
                                vm.notifications.unread = response.notificationCount;
                            }
                        }
                    });
                }
            }

            userManager.clearExpiredCache();

            $rootScope.refreshToken = $interval(refresh, 30000);
        }

        $rootScope.$on('NotificationsMarkedAsRead', (event, notifications) => {
            const unread = parseInt(vm.notifications.unread) - Object.keys(notifications).length;
            vm.notifications.unread = (unread > 0) ? unread : 0;
        });

        /**
         * filter permissions
         */
        function filterModules(modules) {
            var list = [];

            for (var m in modules) {

                if (modules[m].excludePermission && userManager.hasPermission(modules[m].excludePermission)) {
                    continue;
                }

                if (modules[m].permission && !userManager.hasPermission(modules[m].permission)) {
                    continue;
                }

                if (modules[m].userLink && !vm.user.getLink(modules[m].userLink)) {
                    continue;
                }

                if (modules[m].excludeOnUserLink && vm.user.getLink(modules[m].excludeOnUserLink)) {
                    continue;
                }

                if (modules[m].rootLink && !rootResourceResponse.getLink(modules[m].rootLink)) {
                    continue;
                }

                if (modules[m].moduleEnabled) {
                    var isEnabled = false;

                    if (!Array.isArray(modules[m].moduleEnabled)) {
                        modules[m].moduleEnabled = [modules[m].moduleEnabled];
                    }

                    for (var i = 0, len = modules[m].moduleEnabled.length; i < len; i++) {
                        if (modulesService.isEnabled(modules[m].moduleEnabled[i])) {
                            isEnabled = true;
                            break;
                        }
                    }

                    if (!isEnabled) {
                        continue;
                    }
                }

                if (modules[m].oneOfModulesEnabled && !modulesService.oneOfEnabled(modules[m].oneOfModulesEnabled) && !vm.user.getLink(modules[m].userLink)) {
                    continue;
                }

                if (modules[m].widgets) {
                    modules[m].widgets = filterModules(modules[m].widgets);

                    if (modules[m].widgets.length === 0) {
                        continue;
                    }
                }

                modules[m].labelTranslated = $translate.instant(modules[m].label);
                if (typeof modules[m].srefParameters === 'undefined') {
                    modules[m].srefParameters = {};
                }

                modules[m].srefParameters = JSON.stringify(modules[m].srefParameters);

                list.push(modules[m]);
            }
            return list;
        }

        function sortModules(modules, doSort) {
            var list = [];

            for (var m in modules) {
                if (modules[m].widgets) {
                    modules[m].widgets = sortModules(
                        modules[m].widgets,
                        modules[m].sort === false ? modules[m].sort : true
                    );
                }

                list.push(modules[m]);
            }

            if (doSort) {
                list.sort(function(a, b) {

                    a = a.labelTranslated;
                    b = b.labelTranslated;

                    return a > b ? 1 : (b > a ? -1 : 0);
                });
            }

            return list;
        }

        // process logout if no dirty form
        function logout() {
            var dirtyForm = userManager.checkIsDirty();

            //Trigger notification if dirty form detected and not a submission.
            if(dirtyForm) {
                var answer = confirm($translate.instant('NAVIGATION_CONFIRMATION'));
                if(answer) {
                    processLogout();
                }
            } else {
                processLogout();
            }
        }

        // log the user out of the api, and redirect to login page
        function processLogout() {
            $rootScope.skipDirtyCheck = true;
            $rootScope.username = null;
            $interval.cancel($rootScope.refreshToken);
            apiClient.get(vm.user.getLink('logout')).then(function() {
                vm.user = {};
                $window.localStorage.removeItem('userIdFilter');
                $window.localStorage.removeItem('userIdName');
                $window.localStorage.removeItem('userIdFilterName');
                $window.localStorage.removeItem('userFilterName');
                $window.localStorage.removeItem('siteAssociateTypeFilterName');
                $window.localStorage.removeItem('siteAssociateTypeFilter');
                $window.localStorage.removeItem('regionFilter');
                $window.localStorage.removeItem('regionFilterName');
                systemNotifications.clean();
                userManager.logout();
                $state.go('login');
            });
        }

        function goToNotificationLink(notification) {
            markAsRead(notification);
            notificationsService.goToNotificationLink(notification);
        }

        function adjustNotificationsMenu() {
            const headerButtonHeight = angular.element('#notifications-menu-button').height() || 0;
            const loginBarHeight = angular.element('#loggedInAsBar').height() || 0;
            const $window = angular.element(window);

            // hide or show notification items if they are/not visible on the screen
            setTimeout(() => {
                const $menu = angular.element('#notifications-menu');
                const $menuItems = $menu.find('li.notification-item');

                for (let i = 0; i < $menuItems.length; i++) {
                    $menuItems.eq(i).removeClass('notification-item-hidden');
                }

                for (let i = $menuItems.length; i >= 0; i--) {
                    if ($menu.height() > ($window.height() - headerButtonHeight - loginBarHeight)) {
                        $menuItems.eq(i).addClass('notification-item-hidden');
                    }
                }
            });
        }

        window.addEventListener('resize', adjustNotificationsMenu);

        function getNotifications(page, toggledopen, loader = true) {
            if (loader) {
                vm.loading = true;
            }
            vm.notifications.page = page;

            if (toggledopen === true) {
                // Get unread notifications
                apiClient.get(user._links.notifications.href, {
                    page: vm.notifications.page,
                    order: 'read,-createdAt',
                    limit: 10,
                }).then(function(response) {
                    vm.notifications.list = response.notifications;
                    vm.notifications.listTotal = response.count;
                    if (loader) {
                        vm.loading = false;
                    }
                    adjustNotificationsMenu();
                });
            } else {
                adjustNotificationsMenu();
            }
        }

        function markAsRead(notification, event) {
            if (event) {
                event.stopPropagation();
            }

            notification.loading = true;

            notificationsService.markAsRead(notification).then(function() {
                getNotifications(vm.notifications.page, true, false);

                apiClient.get(user.getLink('notificationCount')).then(function(response) {
                    vm.notifications.unread = response.getData().notificationCount;
                });
            });
        }

        function markAllAsRead() {
            vm.loading = true;

            apiClient.update(user.getLink('markallnotificationasread'), {}).then(function() {
                getNotifications(vm.notifications.page, true);
                vm.loading = true;
                apiClient.get(user.getLink('notificationCount')).then(function(response) {
                    vm.notifications.unread = response.getData().notificationCount;
                    vm.loading = false;
                });
            });
        }

        function revertToOriginalUser() {
            userManager.revertToOriginalUser();
        }

        $scope.$on('$destroy', function(event) {
            $interval.cancel(interval);
            $timeout.cancel(updatesTimeout);

            if ($rootScope.refreshToken != null) {
                $interval.cancel($rootScope.refreshToken);
            }
        });

        function goToJob() {
            if(user.getLink('jobs')) {
                apiClient.get(user.getLink('jobs') + '?jobId=' + vm.job).then(function(response) {
                    if (response.count) {
                        vm.job = '';
                        $state.go('dashboard.user.jobs.list.details.status', {
                            resource: base64.encode(response.jobs[0]._links.self.href)
                        });
                    } else {
                        messenger.error('NO_JOB_FOUND');
                    }
                });
            }
            else {
                messenger.error('NO_JOB_FOUND');
            }
        }

        function goToQuote() {
            if(user.getLink('quotes')) {
                apiClient.get(user.getLink('quotes'), {id: vm.quote}).then(function(response) {
                    if (response.count) {
                        angular.forEach(response.quotes, function(value, index) {
                            if (value.id != vm.quote) {
                                return;
                            }

                            vm.quote = '';
                            $state.go('dashboard.user.quote-requests.list.details.quote.workflow', {
                                quoteResource: base64.encode(value.getLink('self'))
                            });
                        });
                    } else {
                        messenger.error('NO_QUOTE_FOUND');
                    }
                });
            } else {
                messenger.error('NO_QUOTE_FOUND');
            }
        }

        function checkWidgetRelatedRoutes(widget) {
            if (!widget.relatedRoutes && typeof widget === 'object') {
                return false;
            }

            var route = (widget.relatedRoutes) ? widget.relatedRoutes : widget;

            if (!Array.isArray(route)) {
                return $state.$current.name.indexOf(route) !== -1 ? true : false;
            }

            for (var item in route) {
                if (checkWidgetRelatedRoutes(route[item])) {
                    return true;
                }
            }
            return false;
        }

        function checkActiveState(widget) {
            var validParams = {};
            angular.forEach($state.params, function(value, index) {
                if (!(value === null || typeof value === 'undefined')) {
                    validParams[index] = value;
                }
            });

            return (
                $state.current.name.indexOf(widget.sref) === 0 ||
                $state.current.name.indexOf(widget.relatedRoutes) === 0
            ) &&
            (
                !widget.excludeParameters ||
                lodash.intersection(widget.excludeParameters, lodash.keys(validParams)).length === 0
            ) &&
            (
                !widget.mustIncludeParameters ||
                lodash.intersection(widget.mustIncludeParameters, lodash.keys(validParams)).length === widget.mustIncludeParameters.length
            )
        }

        var script = angular.element('script[src^="/updates"]'),
            src = script.attr('src');

        function checkForUpdates() {
            $http.get(src).then(function(response) {
                setTimeout(30000);
            }, function(response) {
                if (response.status === 404) {
                    $uibModal.open({
                        templateUrl: '/modules/common/updates-available/updates-available.html',
                        controller: 'UpdatesAvailableController',
                        controllerAs: 'vm'
                    }).result.then(function(response) {
                        userManager.clearCache();
                        setTimeout(response);
                    }, function(response) {
                        setTimeout(response);
                    });
                } else if (response.status === 503) {
                    $window.location.reload();
                } else {
                    setTimeout(30000);
                }
            });

            function setTimeout(timeout) {
                if (!timeout) {
                    return;
                }

                updatesTimeout = $timeout(function() {
                    checkForUpdates();
                }, timeout);
            }
        }

        checkForUpdates();

        function checkForSessionExpiration() {
            interval = $interval(function() {
                var FIFTEEN_MINUTES = 1000 * 60 * 15;
                var ONE_DAY = 1000 * 60 * 60 * 24;

                var tokenExpiringSoon = ($window.localStorage.getItem('tokenExpiration') - FIFTEEN_MINUTES < new Date().getTime());

                if (elbSettings.getSetting('okta_sign_on_enabled').value && tokenExpiringSoon) {
                    ssoService.isUserRequiredToLoginWithSso().then(function (response) {
                       if (response.userRequiredToLoginWithSso) {
                           ssoService.renewToken();
                       } else {
                           renewToken();
                       }
                    });
                } else if (tokenExpiringSoon && !sessionExpirationModal) {
                    sessionExpirationModal = $uibModal.open({
                        templateUrl: '/modules/common/extend-session/extend-session.html',
                        controller: 'ExtendSessionController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        keyboard: false
                    }).result.then(function(response) {
                        sessionExpirationModal = null;
                        var params = {source : 'web-extension'};
                        if ($window.localStorage.getItem('originalUser')) {
                            var originalUser = JSON.parse($window.localStorage.getItem('originalUser'));
                            $http.post(API_URL + originalUser.tokenLink, {token: originalUser.token, source: 'web-extension'})
                                .then(function(response) {
                                    $window.localStorage.setItem('tokenExpiration', new Date().getTime() + ONE_DAY);

                                    originalUser.token = response.data.token;
                                    $window.localStorage.setItem('originalUser', JSON.stringify(originalUser));
                                    apiClient.create(userManager.user.getLink('token'), params).then(function(response) {
                                        $window.localStorage.setItem('token', response.token);
                                    });
                                });
                        } else {
                            renewToken();
                        }
                    });
                }
            }, 15000);
        }

        function renewToken() {
            var ONE_DAY = 1000 * 60 * 60 * 24;
            apiClient.create(user.getLink('token'), {source : 'web-extension'}).then(function (response) {
                $window.localStorage.setItem('token', response.token);
                $window.localStorage.setItem('tokenExpiration', new Date().getTime() + ONE_DAY);
            });
        }

        checkForSessionExpiration();

        $scope.$on('$stateChangeStart', function() {
            vm.showLoader = true;
        });

        $scope.$on('$stateChangeSuccess', function () {
            $rootScope.dirtyFormInView = false;
            vm.showLoader = false;
        });

        $scope.$on('$stateChangeAborted', function () {
            vm.showLoader = false;
        });
    }
})();
