(function () {
    'use strict';

    angular
        .module('elogbooks.common.contracts')
        .controller('ContractAddEditController', ContractAddEditController);

    ContractAddEditController.$inject = [
        '$state',
        'apiClient',
        'contractResponse',
        'selectedSiteResponse',
        'siteResponse',
        'siteCollectionResponse',
        'requestDataFactory',
        '$translate',
        'config',
        'crudService',
        '$scope',
        '$rootScope',
        'contactDocumentResponse',
        'serviceTypesCollectionResponse',
        'modulesService',
        'user',
        'userManager',
        '$stateParams'
    ];

    function ContractAddEditController(
        $state,
        apiClient,
        contractResponse,
        selectedSiteResponse,
        siteResponse,
        siteCollectionResponse,
        requestDataFactory,
        $translate,
        config,
        crudService,
        $scope,
        $rootScope,
        contactDocumentResponse,
        serviceTypesCollectionResponse,
        modulesService,
        user,
        userManager,
        $stateParams
    ) {
        var vm = this;

        vm.data = requestDataFactory.createRequest(getDefaults(contractResponse));
        vm.contractResponse = contractResponse;
        vm.siteCollectionResponse = siteCollectionResponse;
        vm.siteResponse = null;
        vm.config = config;
        vm.editMode = config.editMode || false;
        vm.siteRoute = $state.current.name === 'dashboard.user.sites.list.details.contracts.list.add' ? true : false;
        vm.serviceProviderRoute = $stateParams.spResource ? true : false;
        vm.selectedSiteResponse = selectedSiteResponse;

        if (siteResponse && siteResponse._links !== 'undefined') {
            vm.siteResponse = siteResponse;
        } else if (selectedSiteResponse && selectedSiteResponse._links !== 'undefined') {
            vm.siteResponse = selectedSiteResponse.getData();
        }

        vm.changeSite = changeSite;
        vm.create = createAction;
        vm.update = updateAction;
        vm.removeFile = removeFile;
        vm.submitCreateOrUpdate = submitCreateOrUpdate;
        vm.contractTypeChanged = contractTypeChanged;

        var emptyFile = {
            file: null,
            title: null,
            _links: {}
        };

        vm.file = angular.copy(emptyFile);

        if (contactDocumentResponse && typeof contactDocumentResponse._links !== 'undefined') {
            vm.file.title = contactDocumentResponse.title;
            vm.file.file = {
                filename: contactDocumentResponse.filename,
                title: contactDocumentResponse.title,
                type: contactDocumentResponse._links.resource.type,
                _links: {
                    file: {
                        href: contactDocumentResponse._links.self.href
                    }
                }
            };
        }

        function removeFile($index) {
            vm.file = angular.copy(emptyFile);
        }

        if (!config.editMode) {
            vm.siteSelect = {
                response: siteCollectionResponse,
                link: siteCollectionResponse ? siteCollectionResponse.getLink('self') : siteResponse.getLink('self'),
                responseKeyPath: 'sites',
                onSelect: changeSite,
                onRemove: removeSite,
                required: true,
                disabled: vm.siteRoute,
                populateDisabled: true
            };

            vm.partnershipSelect = {
                disabled: true,
                responseKeyPath: 'partnerships',
                linkParameters: { active:1 },
                itemHrefPath: '_links.serviceprovider.href',
                itemValuePath: '_links.serviceprovider.title',
                onSelect: changePartnership,
                onRemove: removePartnership,
                required: false
            };

            vm.serviceTypeSelect = {
                response: serviceTypesCollectionResponse,
                responseKeyPath: 'serviceTypes',
                linkParameters: { active:1 },
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
                required: true
            };

            if (vm.siteResponse !== null) {
                vm.isSiteDefined = true;

                /** Force site to be loaded */
                vm.data.site = siteResponse;
                vm.siteSelect.selected = {
                    object:siteResponse,
                    href:siteResponse.getLink('self'),
                    value:siteResponse.name
                };
                vm.changeSite();
            }
        }

        vm.isHexagonIntegration = modulesService.isEnabled('hexagon_p2p');
        if (vm.isHexagonIntegration) {
            vm.hexagonModel = {};
            vm.hexagonLoading = false;
            vm.hexagonUrl = config.hexagonNominalCodesUrl;
            vm.isValidP2PUser = isValidP2PUser(siteResponse);

            if (vm.contractResponse && contractResponse.hasOwnProperty('getLinkAttribute')) {
                var siteRef = vm.contractResponse.getLinkAttribute('site', 'reference');
                if (siteRef) {
                    vm.hexagonPropertyReference = siteRef;
                    vm.hexagonLoading = vm.isValidP2PUser;
                }
            }
            $scope.$on('hexagonNominalCodes', function(event, args) {
                vm.hexagonLoading = args.loading;
            });
            $scope.setValidateRatios = function(validateFunction) {
                $scope.validateRatios = validateFunction;
            };
        }

        // specify billing frequency options
        vm.frequencyOptions =   {
            daily: {value: 'daily', title: $translate.instant('DAILY')},
            weekly: {value: 'weekly', title: $translate.instant('WEEKLY')},
            monthly: {value: 'monthly', title: $translate.instant('MONTHLY')},
            yearly: {value: 'yearly', title: $translate.instant('YEARLY')},
        };

        // specify notice period options
        vm.noticedPeriodTypes =   {
            daily: {value: 'd', title: $translate.instant('DAYS')},
            weekly: {value: 'w', title: $translate.instant('WEEKS')},
            monthly: {value: 'm', title: $translate.instant('MONTHS')},
            yearly: {value: 'y', title: $translate.instant('YEARS')},
        };

        vm.contractTypes = [
            {
                value: 'client',
                label: $translate.instant('CONTRACT_TYPE_CLIENT')
            },
            {
                value: 'consultant',
                label: $translate.instant('CONTRACT_TYPE_CONSULTANT')
            },
            {
                value: 'framework',
                label: $translate.instant('CONTRACT_TYPE_FRAMEWORK')
            },
            {
                value: 'PSA',
                label: $translate.instant('CONTRACT_TYPE_PSA')
            },
            {
                value: 'supplier',
                label: $translate.instant('CONTRACT_TYPE_SUPPLIER')
            },
            {
                value: 'other',
                label: $translate.instant('CONTRACT_TYPE_OTHER')
            }
        ];

        vm.billingPractices = [
            {
                title: $translate.instant('BILLING_PRACTICE_IN_ADVANCE'),
                value: 'BILLING_PRACTICE_IN_ADVANCE'
            },
            {
                title:  $translate.instant('BILLING_PRACTICE_IN_ARREARS'),
                value: 'BILLING_PRACTICE_IN_ARREARS'
            }
        ];

        /**
         * Submit contract to api
         *
         */
        function createAction ($submitContract) {
            var createLink;

            var postData = angular.copy(vm.data);

            prepareBillingPractice(postData);

            // add file record if not empty
            if (vm.file.file !== null) {
                postData.file = angular.copy(vm.file);
                postData.file._links.file = angular.copy(postData.file.file._links.file);
            }

            var messageStart = 'CONTRACT';

            if ($submitContract && $submitContract === true) {
                messageStart = 'CONTRACT_SUBMITTED_AND';
                //check if all required fields are set
                var requiredFields = [
                    'name',
                    'site',
                    'contractType',
                    'startAt',
                    'reviewAt',
                    'expiresAt',
                    'value'
                ];

                if (!checkFields(requiredFields, postData)) {
                    return;
                }

                //check service type selects
                if (vm.serviceTypeSelect.selected === undefined || vm.serviceTypeSelect.selected === null) {
                    return;
                }

                postData.submitContract = true;
            }

            if (vm.siteResponse && typeof vm.siteResponse._links !== 'undefined') {
                createLink = vm.siteResponse.getLink('contracts');
            } else {
                createLink = vm.data.site.getLink('contracts');
            }

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                postData._links.serviceprovider = vm.partnershipSelect.selected;
            }

            if ($stateParams.spResource) {
                postData._links.serviceprovider = {'href': $stateParams.resource.decode()};
            }

            if (typeof vm.serviceTypeSelect.selected !== 'undefined') {
                postData._links.servicetype = vm.serviceTypeSelect.selected;
            }

            if (vm.isHexagonIntegration && vm.isValidP2PUser) {
                $scope.validateRatios(true);
                if (vm.hexagonModel.ratioError) {
                    return;
                }
                delete vm.hexagonModel.ratioError;
                postData.nominalLines = vm.hexagonModel;
            }

            // remove unused properties
            delete postData.site;
            delete postData.partnership;

            var listState = $state.current.name.substring(0, $state.current.name.lastIndexOf('.list') + 5);

            return crudService.create(
                createLink,
                postData,
                listState + '.details.workflow',
                'contractResource',
                messageStart
            );
        }

        /**
         * update contract via api
         */
        function updateAction ($submitContract) {

            var updateLink = config.contractResource;
            var putData = angular.copy(vm.data);

            prepareBillingPractice(putData);

            // add file record if not empty
            if (vm.file.file !== null) {
                putData.file = angular.copy(vm.file);
                putData.file._links.file = angular.copy(putData.file.file._links.file);
            }

            var messageStart = 'CONTRACT';

            if ($submitContract && $submitContract === true) {
                messageStart = 'CONTRACT_SUBMITTED_AND';
                //check if all required fields are set
                var requiredFields = [
                    'name',
                    'reviewAt',
                    'expiresAt',
                    'value'
                ];

                if (!checkFields(requiredFields, putData)) {
                    return;
                }

                putData.submitContract = true;
            }

            if (vm.isHexagonIntegration && vm.isValidP2PUser) {
                $scope.validateRatios(true);
                if (vm.hexagonModel.ratioError) {
                    return;
                }
                delete vm.hexagonModel.ratioError;
                putData.nominalLines = vm.hexagonModel;
            }

            // remove non-updatable properties
            delete putData._links;
            delete putData.contractType;
            delete putData.startAt;
            delete putData.id;
            delete putData.status;
            delete putData.createdAt;

            return crudService.update(
                updateLink,
                putData,
                $state.get('^'),
                $state.get('^.^.^'),
                messageStart
            );
        }

        function submitCreateOrUpdate() {
            if (vm.editMode) {
                updateAction(true);
            } else {
                createAction(true);
            }
        }

        function checkFields(requiredFields, data) {
            var fieldsValid = true;

            //check standard fields
            angular.forEach(requiredFields, function(field) {
                if (data[field] === undefined || data[field] === null) {
                    fieldsValid = false;
                    return false;
                }
            });

            // if contract type == 'supplier' then service provider is required
            if (
                !vm.editMode &&
                data.contractType === "supplier" &&
                vm.partnershipSelect.selected === undefined
                ) {
                return false;
            }

            //check file name if file is added
            if(vm.file.file) {
                if (vm.file.title === null) {
                    fieldsValid = false;
                    return false;
                }
            }

            //skip dirty form check
            $rootScope.skipDirtyCheck = true;

            return fieldsValid;
        }

        /**
         * Load Contract details defaults
         *
         * @param {object} contractResponse
         */
        function getDefaults(contractResponse) {
            if (typeof contractResponse._links !== 'undefined') {
                contractResponse.value = parseFloat(contractResponse.value);
                contractResponse.reviewAt = contractResponse.reviewAt ? new Date(contractResponse.reviewAt) : null;
                contractResponse.expiresAt = contractResponse.expiresAt ? new Date(contractResponse.expiresAt) : null;
                contractResponse.lastTenderedAt = contractResponse.lastTenderedAt ? new Date(contractResponse.lastTenderedAt) : null;
            } else {
                // set reminder yesno to false by default
                contractResponse.reviewReminder = false;
                contractResponse.expiresReminder = false;
            }

            return contractResponse;
        }

        function isValidP2PUser(siteResponse) {
            return siteResponse
                && siteResponse._embedded.approvers.contractApprovers.approvers.count > 0
                && siteResponse._embedded.approvers.contractApprovers.approvers.memberships[0]._links.user.href === user.getLink('self')
                && userManager.hasPermission('site_permission_p2p');
        }

        /**
         * Load Site and setup service provider
         */
        function changeSite() {
            if (typeof vm.siteSelect.selected !== 'undefined') {

                vm.partnershipSelect.disabled = false;

                apiClient.get(vm.siteSelect.selected.href).then(function (response) {
                    vm.data.site = response;
                    vm.partnershipSelect.link = response._links.partnerships.href;
                    if (vm.isHexagonIntegration) {
                        vm.isValidP2PUser = isValidP2PUser(response);
                        vm.hexagonPropertyReference = response.reference;
                        vm.hexagonLoading = vm.isValidP2PUser;
                    }
                });
            }

            if (vm.data.id && typeof vm.data._links.serviceprovider !== 'undefined') {
                vm.partnershipSelect.selected = vm.data._links.serviceprovider;
            }

            if (vm.data.site && typeof vm.data.site._links !== 'undefined') {
                /** Load service providers who belong to a site */
                if (typeof vm.data.site._links.partnerships !== 'undefined') {
                    vm.partnershipSelect.disabled = false;
                    vm.partnershipSelect.link = vm.data.site._links.partnerships.href;
                }
            }
        }

        /**
         * Load service provider, service type form elements
         */
        function changePartnership () {
            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                vm.serviceTypeSelect.disabled = false;
                // partnership request
                apiClient.get(vm.partnershipSelect.selected.object._links.self.href).then(function (response) {
                    var partnershipResponse = response;
                    // partnership service types request
                    apiClient.get(response.getLink('servicetypes')).then(function (response) {
                        // if there is not service types for partnership get service provider service types then
                        if (response.serviceTypes.length > 0) {
                            vm.serviceTypeSelect.buildItems(response);
                        } else {
                            // service provider service types request
                            apiClient.get(partnershipResponse.getLink('serviceproviderservicetypes')).then(function (response) {
                                if (response.serviceTypes.length > 0) {
                                    vm.serviceTypeSelect.buildItems(response);
                                } else {
                                    vm.serviceTypeSelect.buildItems(serviceTypesCollectionResponse);
                                }
                            });
                        }
                    });
                });
            }

            if (vm.data.id && typeof vm.data._links.serviceType !== 'undefined') {
                vm.serviceTypes.selected = vm.data._links.serviceType;
            }
        }

        /**
         * Clear all form fields that are site related.
         */
        function removeSite() {
            vm.partnershipSelect.clear();
            vm.partnershipSelect.disabled = true;
            if (vm.isHexagonIntegration) {
                vm.hexagonPropertyReference = null;
            }
        }

         /**
         * Clear all form fields that are site related.
         */
        function removePartnership() {
            vm.serviceTypeSelect.clear();
            vm.serviceTypeSelect.buildItems(serviceTypesCollectionResponse);
        }

        function prepareBillingPractice (data) {
            switch (data.billingPractice) {
                case 'BILLING_PRACTICE_IN_ADVANCE':
                    data.billingPractice = 0;
                    break;
                case 'BILLING_PRACTICE_IN_ARREARS':
                    data.billingPractice = 1;
                    break;
            }
        }

        function contractTypeChanged() {
            vm.partnershipSelect.required = vm.data.contractType === "supplier";
        }
    }
})();
