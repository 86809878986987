(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestDetailsQuoteDetailsWorkflowController', CommonQuoteRequestDetailsQuoteDetailsWorkflowController);

    CommonQuoteRequestDetailsQuoteDetailsWorkflowController.$inject = ['quoteResponse', 'eventCollectionResponse', 'lodash'];

    function CommonQuoteRequestDetailsQuoteDetailsWorkflowController(quoteResponse, eventCollectionResponse, lodash) {
        var vm = this;
        vm.quoteResponse = quoteResponse;
        
        if (vm.quoteResponse.status === 'submitted' && vm.quoteResponse.quoteValueApproved) {
            var event = lodash.find(eventCollectionResponse.events, { type: 'quote_value_options_accepted'}); 
            vm.valueApprovedBy = event ? event.getLinkAttribute('user', 'title') : vm.quoteResponse.getLinkAttribute('quotevalueapprover', 'title');
        }
    }
})();
