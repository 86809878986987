(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('QuoteSubmitSharedController', QuoteSubmitSharedController);

    QuoteSubmitSharedController.$inject = ['lodash', 'apiClient', '$state', 'messenger', 'quoteResponse', 'quoteCollectionResponse', 'p2pResponse'];

    function QuoteSubmitSharedController(lodash, apiClient, $state, messenger, quoteResponse, quoteCollectionResponse, p2pResponse) {
        var vm = this;
        vm.action = 'submit-shared';
        vm.translation = 'QUOTE_WORKFLOW_SUBMIT';
        vm.noteRequired = false;
        vm.hideNote = true;
        vm.partial = vm.action;
        vm.quoteCollectionResponse = quoteCollectionResponse;
        vm.quoteResponse = quoteResponse;
        vm.toggleMultipleOptions = toggleMultipleOptions;
        vm.selectAllOptions = selectAllOptions;
        vm.toggleMultipleFiles = toggleMultipleFiles;
        vm.toggleOption = toggleOption;
        vm.toggleFile = toggleFile;
        vm.showQuote = showQuote;
        vm.submit = submitAction;
        vm.selectedOptions = [];
        vm.valueLabel = 'VALUE';
        vm.allQuoteOptionsSelected = false;

        // File status constants - see Model\File
        vm.fileStatus = {
            processing: 0,
            valid: 1,
            invalid: 2,
        };

        if (p2pResponse !== null) {
            if (p2pResponse.isP2PSite){
                vm.valueLabel = 'P2P_VALUE';
            }
        }

        function showQuote(quote) {
            return quote.options.count > 0
            && (
                (
                    quote.status === 'requested'
                    || quote.status === 'information_requested'
                    && vm.quoteResponse.id === quote.id
                )
                ||
                (
                    quote.isSubQuote
                    && quote.status === 'submitted'
                )
            );
        }

        function toggleMultipleOptions(options) {
            options.selected = !!options.selected;

            lodash.forEach(options.quoteoptions, function(option) {
                option.selected = !!options.selected;
            });
        }

        function selectAllOptions() {
            lodash.forEach(vm.quoteCollectionResponse.quotes, function(quote) {
                quote.options.selected = vm.allQuoteOptionsSelected;

                lodash.forEach(quote.options.quoteoptions, function(option) {
                    option.selected = vm.allQuoteOptionsSelected;
                });
            });
        }

        function toggleMultipleFiles(optionFiles) {
            optionFiles.selected = !!optionFiles.selected;

            lodash.forEach(optionFiles.files, function(file) {
                if (!file.hasOwnProperty('selected')) {
                    file.selected = false;
                }

                if (file.status === vm.fileStatus.valid) {
                    file.selected = !!optionFiles.selected;
                }
            });
        }

        function toggleOption(option) {
            option.selected = !!option.selected;
        }

        function toggleFile(file) {
            if (!file.hasOwnProperty('selected')) {
                file.selected = false;
            }

            if (file.status === vm.fileStatus.valid) {
                file.selected = !!file.selected;
            }
        }

        function submitAction() {
            var links = [];
            var files = [];
            lodash.forEach(vm.quoteCollectionResponse.quotes, function(quote) {
                lodash.forEach(quote.options.quoteoptions, function(option) {
                    if (option.selected) {
                        links.push(option._links.self);
                    }

                    if (option.files && option.files.files) {
                        lodash.forEach(option.files.files, function(file) {
                            if (file.selected) {
                                files.push(file._links.self);
                            }
                        });
                    }
                });
            });

            if (links.length === 0) {
                messenger.error('AT_LEAST_ONE_OPTION_MUST_BE_SELECTED');
                return;
            }

            vm.data = {
                options: { links: links },
                files: { links: files }
            };

            return apiClient.create(
                quoteResponse.getLink('submit-shared'),
                vm.data,
                null,
                true
            ).then(function() {
                $state.go($state.get('^'), { quoteOptionResource: quoteResponse.getLink('self').encode() }, { reload: $state.get('^.^.^.^') }).then(function() {
                    messenger.success('QUOTE_OPTIONS_SUBMITTED_SUCCESS');
                });
            });
        }
    }
})();
