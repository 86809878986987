(function() {
    'use strict';

    angular
        .module('elogbooks.common.sso')
        .controller('SsoController', SsoController);

    function SsoController(ssoService, userManager, $state, $window, rootResource) {
        var vm = this;
        vm.linkUser = linkUser;
        vm.logout = logout;
        vm.user = userManager.user;
        vm.ssoType = JSON.parse($window.localStorage.getItem('ssoParameters')).type.toUpperCase();

        vm.themePrefix = rootResource ? rootResource.theme : 'elb';
        vm.themeLogo = `/${vm.themePrefix}-logo.svg`;

        function linkUser() {
            ssoService.authorise();
        }

        function logout() {
            userManager.logout();
            $state.go('login');
        }
    }
})();
